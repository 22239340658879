import React, { Component } from 'react';
import './App.css';

import Facebook from './components/Facebook';

class App extends Component {
  render() {
    return (
      <div className="App mt-5">
        <Facebook/>
      </div>
    );
  }
}

export default App;
