import React, { useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import axios from 'axios';

// const API_PATH = '/app/api/contact/index.php';

function facebookLogout() {
  /*global FB*/
  try {
    if (FB.getAccessToken() != null) {
        FB.logout(function(response) {
            // user is now logged out from facebook do your post request or just redirect
            window.location.reload();
        });
    } else {
        // user is not logged in with facebook, maybe with something else
        window.location.reload();
    }
  } catch (err) {
      // any errors just logout
      window.location.reload();
  }
}

const Facebook = () => {
  const initialState= {
    isLoggedIn: false,
    userID: '',
    name: '',
    email: '',
    picture: '',
    access_token: '',
    page_data : [],
    instagram_data: [],
    instagram_id: '',
    loading: false,
    page_id: '',
    error: null,
    mailSent: false
  }

  const [state, setState] = useState(initialState);

  const responseFacebook = response => {
    // console.log(response);
    if(!response.status){
      setState(prevState => ({
        ...prevState,
        isLoggedIn: true,
        userId: response.userID,
        name: response.name,
        email: response.email,
        picture: response.picture.data.url,
        access_token: response.accessToken,
        error: ''
      }));
    }

    if (sessionStorage.page_data) {
      var pdata = JSON.parse(sessionStorage.getItem('page_data'));
      setState(prevState => ({
        ...prevState,
        page_data: pdata
      }));
      // getInstagramBusinessAccounts();
    } else {
      getLongLivedToken(response.accessToken);
    }
  }

  // const componentClicked = () => console.log("clicked");

  const facebookFailure = (response) => {
    if (response.status === 'not_authorized') {
      setState(prevState => ({
        // error: 'This app is in Development mode and can therefore only accept authentication requests from developer test users. Please log out from Facebook and log in to this app using a test user account',
        ...prevState,
        error: 'You are not authorized to use this app. Please contact Pool',
      }));
    }
  }

  const getLongLivedToken = (userAccessToken) => {
    const clientId = '822347674549400';
    const clientSecret = '5481a15b9c9060798d9e319608bca276';
    axios.get(`https://graph.facebook.com/oauth/access_token?client_id=${clientId}&client_secret=${clientSecret}&grant_type=fb_exchange_token&fb_exchange_token=${userAccessToken}`)
    .then((response) => {
      getNeverExpiringToken(response.data.access_token);
    });
  }

  const getNeverExpiringToken = (long_lived_token) => {
    //console.log(long_lived_token);
    axios.get(`https://graph.facebook.com/me/accounts?limit=500&access_token=${long_lived_token}`)
        .then((response) => {
          //console.log("Never Expiring Access Token");
          setState(prevState => ({
            ...prevState,
            page_data: response.data.data
          }));
          //console.log(long_lived_token);
          //getInstagramBusinessAccounts(response.data.data);
          sessionStorage.setItem("page_data", JSON.stringify(response.data.data));
          //getInstagramBusinessAccounts();
        });
  }

  const getInstagramBusinessAccount = (pageId) => {
    if (state.page_data) {
      state.page_data.map((data, i) => {
        if(pageId === data.id) {
          axios.get(`https://graph.facebook.com/${data.id}?fields=instagram_business_account&access_token=${data.access_token}`)
            .then((response) => {
              //console.log(response.data.instagram_business_account.id);
              getInstagramData(response.data.instagram_business_account.id, data.access_token);
              setState(prevState => ({
                ...prevState,
                error: ''
              }));
            })
            .catch(error => {
              console.log(error);
              setState(prevState => ({
                ...prevState,
                instagram_data: [],
                instagram_id: '',
                error: 'There appears to be no Instagram business account connected to this Facebook page. Please choose an other page or connect an Instagram business account to the page and try again.'
              }));
              // sendEmail();
            });
        }
        return null;
     })
    }
  }


  const getInstagramData = (account_id, access_token) => {
    axios.get(`https://graph.facebook.com/${account_id}/media?fields=media_url,caption,permalink,username,like_count,timestamp,shortcode&access_token=${access_token}`)
      .then((response) => {
        //console.log(response.data.data);
        setState(prevState => ({
          ...prevState,
          instagram_data: Object.values(response.data.data),
          instagram_id: account_id
        }));
        // sendEmail();
      });
  }

  const handleChange = (event) => {
    const pageId = event.target.value;
    setState(prevState => ({
      ...prevState,
      page_id: pageId,
    }));
    getInstagramBusinessAccount(pageId);
  }

  const handleLogout = (event) => {
    event.preventDefault();
    facebookLogout();
  }

  /* const sendEmail = () => {
    let config = {
      headers: { 'content-type': 'application/json' },
    }
    axios.post(`${API_PATH}`, state,config)
      .then(result => {
        setState(prevState => ({
          ...prevState,
          mailSent: result.data.sent
        }))
      })
      .catch(error => console.log(error));
  }; */

  let fbContent;
  if(state.isLoggedIn) {
    fbContent = (
      <div style={{
        width: '400px',
        margin: '0 auto 30px',
        background: '#f4f4f4',
        padding: '20px'
      }}>
        <h2>You are logged in</h2>
      </div>
    );
  } else {
    fbContent = (<div>
      <header className="header">
        <h1 className="App-title">Pool Access Token Generator</h1>
      </header>
      <p className="App-Intro">Log in to generate an access token</p>
      <FacebookLogin
      appId="822347674549400"
      autoLoad={false}
      fields="name,email,picture"
      scope="pages_read_engagement,pages_show_list,pages_read_user_content,instagram_basic,public_profile"
      // onClick={this.componentClicked}
      callback={responseFacebook}
      onFailure={facebookFailure}
      render={renderProps => (
        <button onClick={renderProps.onClick} className="loginBtn">Login</button>
      )}      
      /></div>);
  }

  let accessTokens;
  if (state.page_data) {
    accessTokens = state.page_data.map((data, i) => {
      if(state.page_id === data.id) {
        return (
          <div key={i}>
            <p>Name: {data.name}</p>
            <p>Page ID: {data.id}</p>
            <div><label htmlFor={i}>Page Access Token:</label></div>
            <textarea id={i} key={i} rows="5" cols="50" value={data.access_token} readOnly/>
          </div>
        )
      } return null;
    });
  }

  let pageName;
  if (state.page_data) {
    pageName = state.page_data.map((data, i) => {
      if(state.page_id === data.id) {
        return (
          <div key={i}>
            <p>Selected page: {data.name}</p>
          </div>
        )
      } return null;
    });
  }

  let selectOptions;
  if (state.page_data) {
    selectOptions = state.page_data.map((data, i) => {
      return (
        <option key={i} value={data.id}>{data.name}</option>
      )
    });
  }

  let selectBox;
  if (state.page_data && state.page_data.length){
    selectBox =
      (<div><strong>Please select the page you wish to generate an access token for:</strong>
        <select value={state.page_id} onChange={handleChange}>
          <option key='default' value="" disabled={true}>Please select a page</option>
          {selectOptions}
        </select>
      </div>
    );
  }

  /* let instagramData;
  if (state.instagram_data) {
    instagramData = state.instagram_data.map((data, i) => {
      return (
        <div className="card" key={i}>
          <a href={data.permalink} target="_blank" rel="noopener noreferrer">
            <img className="card-img-top img-fluid" src={data.media_url} alt={data.shortcode} />
          </a>
          <div className="card-body">
            <p className="card-text">{data.caption}</p>
            {<i className="far fa-heart">{data.like_count}</i>}
          </div>
        </div> )
    });
  } */

  let instaID;
  if (state.instagram_id !== ''){
    instaID = (<p>Instagram Account ID: {state.instagram_id}</p>)
  }

  let errors;
  if (state.error) {
    errors = (<p className="notice"><i className="fa fa-exclamation-circle" aria-hidden="true"></i> {state.error}</p>)
  }

  let logoutlink;
  if (state.isLoggedIn) {
    logoutlink = (<a href="/#" onClick={handleLogout}>Logout</a>);
  }

  return (
    <div>
      {/*notice*/}
      {fbContent}
      {logoutlink}
      <p class="small">Note that Pool does not save any of the information on this page in any way. This is just a tool for generating access tokens that are needed to enable your website to show your feed. The connection established here is safe and secure and uses Facebook's official API. Only share your Page ID and Access Token with trusted people.</p>
      <div className="container">
        {selectBox}
        {pageName}
        <div className="tokens">
        {accessTokens}
        {instaID}
        </div>
        <div className="card-columns">
          {/* instagramData */}
        </div>
        {errors}
      </div>
    </div>
  );
}

export default Facebook;
